import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { useGameContext } from "@/contexts/game-context";
import { Vocabulary } from "@/components/gameplay/vocabulary";
import { Reward } from "@/components/gameplay/reward";
import { VideoPlayer } from "@/components/gameplay/video-player";
import { InputButton } from "@/components/gameplay/inputs";
import { useIonRouter } from "@ionic/react";
import SongType from "@/types/song-type";
import VocabularyType from "@/types/vocabulary-type";
import StageType from "@/types/stage-type";

export const StageDisplay = () => {
  const { state, eventHandlers } = useGameContext();
  const { phase, stage, vocabulary, videoUrl, audioUrl, playing } = state;
  const { handleEnded, handleAudioEnded, handleTimeUpdate } = eventHandlers;
  const [debugging, setDebugging] = useState(false);

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "d") {
      setDebugging((debugging) => !debugging);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  return (
    <div className="relative flex flex-col gap-4 items-center justify-between grow p-4">
      <div className="w-full flex gap-4 items-center justify-between">
        <VideoPlayer
          src={videoUrl}
          className="sm:absolute sm:top-6 sm:left-6 rounded-full w-1/3 max-w-36 aspect-square ring-2 ring-gray-400 object-cover object-center opacity-75"
          playsInline
          playing={playing}
          onEnded={handleEnded}
          onTimeUpdate={handleTimeUpdate}
        />

        <div className="grow flex justify-center sm:hidden">
          {["playback", "input", "audio_hint_playback"].includes(phase) &&
            vocabulary && (
              <h2 className="text-4xl text-center">{vocabulary.name}</h2>
            )}
        </div>
      </div>

      <audio
        src={audioUrl}
        autoPlay
        onEnded={handleAudioEnded}
        className="absolute"
      />

      <div
        className={clsx(
          {
            "opacity-100": [
              "playback",
              "input",
              "reward",
              "audio_hint_playback",
            ].includes(phase),
            "opacity-0": ![
              "playback",
              "input",
              "reward",
              "audio_hint_playback",
            ].includes(phase),
          },
          "grow transition-opacity flex flex-col items-center justify-center",
        )}
      >
        {["playback", "input", "audio_hint_playback"].includes(phase) &&
          vocabulary && (
            <div>
              <Vocabulary key={vocabulary?.id} vocabulary={vocabulary} />
              <h2 className="text-4xl text-center hidden sm:block pt-4">
                {vocabulary.name}
              </h2>
            </div>
          )}

        {phase === "reward" && <Reward key={stage?.id} />}
      </div>

      {process.env.NODE_ENV === "development" && debugging && (
        <div className="absolute right-0 top-0 bottom-0 w-[400px] bg-gray-300 p-2 overflow-auto">
          <h3 className="pb-4 font-semibold">DEBUGGER</h3>
          <ul>
            {Object.entries(state).map(([key, value]) => (
              <li key={key} className="whitespace-nowrap">
                <strong>{key}</strong>:{" "}
                <span>
                  {["string", "number", "boolean"].includes(typeof value)
                    ? value.toString()
                    : value == null
                      ? "null"
                      : JSON.stringify(
                          {
                            id: (value as SongType | VocabularyType | StageType)
                              ?.id,
                            name: (
                              value as SongType | VocabularyType | StageType
                            )?.name,
                          },
                          null,
                          2,
                        )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const StageInput = () => {
  const {
    state: { phase, stage },
  } = useGameContext();
  const router = useIonRouter();

  const handleExit = () => {
    return router.canGoBack() ? router.goBack() : router.push("/songs");
  };

  if (phase === "stage_complete") {
    return (
      <div className="flex flex-col gap-2 pb-1">
        <div className="text-lg">Stage Complete</div>

        <button className="btn btn-primary max-w-40" onClick={handleExit}>
          Back to songs
        </button>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        {
          "opacity-100": ["input", "audio_hint_playback"].includes(phase),
          "opacity-0": !["input", "audio_hint_playback"].includes(phase),
        },
        "transition-opacity flex flex-col gap-4 pb-2",
      )}
    >
      <div className="sm:text-lg text-base">{stage?.inputPrompt}</div>

      <div className="flex gap-4 justify-evenly">
        {stage?.inputs.map((input, index) => (
          <div key={index} className="w-full">
            <InputButton kind={input} disabled={phase !== "input"} />
          </div>
        ))}
      </div>
    </div>
  );
};
