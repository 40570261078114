import { useMemo } from "react";
import clsx from "clsx";
import { sample } from "lodash";

import VocabularyType from "@/types/vocabulary-type";

type VocabularyProps = {
  vocabulary: VocabularyType;
  className?: string;
};

export const Vocabulary = ({ vocabulary, className }: VocabularyProps) => {
  const randomImage = useMemo(() => sample(vocabulary.images), [vocabulary]);

  return (
    <img
      src={randomImage?.url}
      alt={vocabulary.name}
      className={clsx(
        "sm:h-[500px] h-full w-auto aspect-square object-contain rounded-lg ring-4 ring-gray-200",
        className,
      )}
    />
  );
};
