import { sample } from "lodash";

import vibraphoneChord from "@/assets/rewards/audios/vibraphone-chord.mp3";
import windChimesHarp from "@/assets/rewards/audios/wind-chimes-harp.mp3";

import bus from "@/assets/rewards/images/bus.gif";
import confetti from "@/assets/rewards/images/confetti.gif";
import fireworks from "@/assets/rewards/images/fireworks.gif";
import gnome from "@/assets/rewards/images/gnome.gif";
import kitten from "@/assets/rewards/images/kitten.gif";
import mouse from "@/assets/rewards/images/mouse.gif";
import pig from "@/assets/rewards/images/pig.gif";
import present from "@/assets/rewards/images/present.gif";

import veryGood from "@/assets/rewards/videos/very-good.mp4";
import wellDone from "@/assets/rewards/videos/well-done.mp4";
import yay from "@/assets/rewards/videos/yay.mp4";
import yesGood from "@/assets/rewards/videos/yes-good.mp4";
import youDidGreat from "@/assets/rewards/videos/you-did-great.mp4";

export const randomRewardAudioUrl = () =>
  sample([vibraphoneChord, windChimesHarp]);

export const randomRewardImageUrl = () =>
  sample([bus, confetti, fireworks, gnome, kitten, mouse, pig, present]);

export const randomRewardVideoUrl = () =>
  sample([veryGood, wellDone, yay, yesGood, youDidGreat]);
