import { useEffect, useRef, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useResource } from "@/hooks/resources";
import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { Tabs, Tab } from "@/components/tabs";
import SongType from "@/types/song-type";
import { Modal } from "flowbite-react";
import { Swiper, SwiperSlide } from "swiper/react";
import VocabularyType from "@/types/vocabulary-type";

const SongPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  const songId = match?.params?.id;

  const [song] = useResource<SongType>(`/songs/${songId}`);

  const [playingAudio, setPlayingAudio] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const [currentVocabulary, setCurrentVocabulary] = useState<VocabularyType>();

  useEffect(() => {
    if (!audioRef.current) return;

    if (playingAudio) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [playingAudio]);

  const handleAudioEnded = () => {
    setPlayingAudio(false);
  };

  return (
    <MenuPageLayout title={song?.name}>
      <div className="flex flex-col sm:flex-row gap-4 w-full">
        <section className="shrink-0 w-full sm:w-1/5 flex flex-col gap-4">
          <img
            src={song?.image?.url}
            className="p-2 border shadow-lg aspect-square"
          />

          <Link to={`/songs/${songId}/play`} className="btn-primary">
            PLAY
            <FontAwesomeIcon icon="play" />
          </Link>
        </section>

        <Tabs>
          <Tab label="Preview Song" value="preview-song">
            <video
              src={song?.gameplayPreviewVideo?.url}
              controls
              className="w-full"
            />
          </Tab>

          <Tab label="Lyrics" value="lyrics">
            <div className="flex gap-4 p-4">
              <div>
                <button
                  className="size-16 ring-2 ring-white text-gray-500 bg-gray-100 hover:bg-gray-300 rounded-full flex items-center justify-center"
                  onClick={() => setPlayingAudio(!playingAudio)}
                >
                  {playingAudio ? (
                    <FontAwesomeIcon icon="pause" />
                  ) : (
                    <FontAwesomeIcon icon="play" />
                  )}
                </button>

                <audio
                  src={song?.fullAudio?.url}
                  ref={audioRef}
                  onEnded={handleAudioEnded}
                />
              </div>

              <div
                className="text-2xl leading-loose whitespace-pre-wrap [&>span]:font-semibold"
                dangerouslySetInnerHTML={{ __html: song?.lyricsHtml || "" }}
              />
            </div>
          </Tab>

          <Tab label="Vocabulary" value="vocabulary">
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 p-4">
              {(song?.vocabularies || []).map((vocabulary: VocabularyType) => (
                <button
                  key={vocabulary.id}
                  className="group p-4 border border-solid border-gray-400 rounded-2xl flex flex-col items-center gap-4"
                  onClick={() => setCurrentVocabulary(vocabulary)}
                >
                  <img
                    src={vocabulary.images[0]?.url}
                    className="object-contain object-center w-full aspect-square rounded-lg"
                  />

                  <h3 className="text-xl group-hover:underline">
                    {vocabulary.name}
                  </h3>
                </button>
              ))}
            </div>

            <Modal
              show={!!currentVocabulary}
              position="center"
              onClose={() => setCurrentVocabulary(undefined)}
              popup
              dismissible
            >
              <Modal.Header />
              <Modal.Body>
                <div className="py-2 flex flex-col items-center gap-4 text-black">
                  <Swiper
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    className="w-full rounded-xl ring-4 ring-gray-400"
                  >
                    {currentVocabulary?.images.map((image) => (
                      <SwiperSlide key={image.url}>
                        <img
                          src={image.url}
                          className="block object-cover object-center w-full aspect-square cursor-grab"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <h2 className="text-2xl text-black">
                    {currentVocabulary?.name}
                  </h2>
                </div>
              </Modal.Body>
            </Modal>
          </Tab>
        </Tabs>
      </div>
    </MenuPageLayout>
  );
};

export default SongPage;
