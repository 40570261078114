import { sample } from "lodash";

import doItAgain from "@/assets/retry-prompts/do-it-again.mp4";
import hearItAgain from "@/assets/retry-prompts/hear-it-again.mp4";
import listenToItAgain from "@/assets/retry-prompts/listen-to-it-again.mp4";
import playItAgain from "@/assets/retry-prompts/play-it-again.mp4";
import singItAgain from "@/assets/retry-prompts/sing-it-again.mp4";
import tryItAgain from "@/assets/retry-prompts/try-it-again.mp4";

export const randomRetryPromptUrl = () =>
  sample([
    doItAgain,
    hearItAgain,
    listenToItAgain,
    playItAgain,
    singItAgain,
    tryItAgain,
  ]);
