import { HTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProgressBar from "@/components/ProgressBar";
import { useGameContext } from "@/contexts/game-context";
import StageType from "@/types/stage-type";

export const Controls = (props: HTMLAttributes<HTMLDivElement>) => {
  const { state, controls } = useGameContext();

  const { song, stageNumber, playing } = state;
  const { play, pause, restart, changeStage } = controls;

  return (
    <div {...props}>
      <div className="flex gap-2">
        <button
          className="size-12 ring-2 ring-white text-gray-500 bg-gray-100 hover:bg-gray-300 rounded-full flex items-center justify-center"
          onClick={() => restart()}
        >
          <FontAwesomeIcon icon="backward-step" fixedWidth />
        </button>

        {playing ? (
          <button
            className="size-12 ring-2 ring-white text-gray-500 bg-gray-100 hover:bg-gray-300 rounded-full flex items-center justify-center"
            onClick={() => pause()}
          >
            <FontAwesomeIcon icon="pause" fixedWidth />
          </button>
        ) : (
          <button
            className="size-12 ring-2 ring-white text-gray-500 bg-gray-100 hover:bg-gray-300 rounded-full flex items-center justify-center"
            onClick={() => play()}
          >
            <FontAwesomeIcon icon="play" fixedWidth />
          </button>
        )}
      </div>

      <div className="flex flex-col rounded border border-gray-400 p-2 gap-2 bg-gray-100">
        <div className="flex gap-2 items-center">
          <select
            className="bg-transparent border border-gray-400 rounded py-1 text-sm sm:text-base"
            value={stageNumber}
            onChange={(e) => changeStage(parseInt(e.target.value, 10))}
          >
            {(song?.stages || []).map((stage: StageType) => (
              <option key={stage.id} value={stage.number}>
                {stage.name}
              </option>
            ))}
          </select>

          <button className="rounded-full hover:bg-gray-200 p-2 size-8 border border-gray-400 border-solid flex items-center justify-center">
            <FontAwesomeIcon icon="question" />
          </button>
        </div>

        <div className="rounded border border-gray-400 h-6 w-full bg-white overflow-clip">
          <ProgressBar
            percentage={(100 / 21) * stageNumber}
            text={stageNumber > song.stages.length ? "Stage Complete" : ""}
          />
        </div>
      </div>
    </div>
  );
};
