import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { useResource } from "@/hooks/resources";
import { StageDisplay, StageInput } from "@/components/gameplay/stage";
import { Controls } from "@/components/gameplay/controls";
import { GameProvider } from "@/contexts/game-context";
import Song from "@/types/song-type";
import { useAppContext } from "@/contexts/app-context";

const Gameplay = ({ match }: RouteComponentProps<{ id: string }>) => {
  const songId = match?.params?.id;
  const router = useIonRouter();

  const {
    state: { isLoadingCurrentUser, hasActiveSubscription },
  } = useAppContext();

  const [song] = useResource<Song>(`/songs/${songId}`);

  const handleExit = () => {
    return router.canGoBack() ? router.goBack() : router.push("/songs");
  };

  if (!isLoadingCurrentUser && !hasActiveSubscription) {
    return <Redirect to="/songs" />;
  }

  return (
    <IonPage>
      <IonContent fullscreen className="bg-white">
        {song && (
          <GameProvider song={song}>
            <div className="flex flex-col h-screen">
              <StageDisplay />

              <div className="flex flex-col md:flex-row border-t-2 border-gray-400">
                <div className="h-[134px] sm:h-auto bg-blue-200 grow border-b-2 md:border-r-2 md:border-b-0 border-gray-400 p-2 sm:p-4">
                  <StageInput />
                </div>

                <div className="flex flex-col gap-x-2">
                  <div className="flex gap-4 items-center justify-between p-2">
                    <h3>{song?.name}</h3>

                    <button
                      className="btn-default bg-gray-200 hover:bg-gray-300 w-auto rounded-full"
                      onClick={handleExit}
                    >
                      Exit
                    </button>
                  </div>

                  <Controls className="bg-gray-200 flex justify-between grow items-center p-2 gap-2" />
                </div>
              </div>
            </div>
          </GameProvider>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Gameplay;
