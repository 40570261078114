import {
  createContext,
  FC,
  PropsWithChildren,
  ReactEventHandler,
  Reducer,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useImmerReducer } from "use-immer";

import SongType from "@/types/song-type";
import VocabularyType from "@/types/vocabulary-type";
import StageType from "@/types/stage-type";
import { randomRewardAudioUrl, randomRewardVideoUrl } from "@/utils/rewards";
import { randomRetryPromptUrl } from "@/utils/retry-prompts";
import { isEmpty, sample } from "lodash";
import { request } from "@/utils/request";
import { useAppContext } from "./app-context";

type GameContextType = {
  state: GameState;
  input: GameInput;
  controls: GameControls;
  eventHandlers: GameEventHandlers;
};

type GameInput = {
  yes: () => void;
  no: () => void;
  next: () => void;
  replay: () => void;
};

type GameControls = {
  play: () => void;
  pause: () => void;
  restart: () => void;
  changeStage: (stageNumber: number) => void;
};

type GameEventHandlers = {
  handleEnded: ReactEventHandler<HTMLVideoElement>;
  handleTimeUpdate: ReactEventHandler<HTMLVideoElement>;
  handleAudioEnded: ReactEventHandler<HTMLAudioElement>;
};

type InternalState = {
  phase:
    | "prompt"
    | "playback"
    | "input"
    | "reward"
    | "retry_prompt"
    | "solution_playback"
    | "audio_hint_playback"
    | "stage_complete";
  stageNumber: number;
  retryCount: number;
  playing: boolean;
  vocabularyCueIndex: number;
  lastAction?: GameAction["type"];
};

type GameState = InternalState & {
  song: SongType;
  stage?: StageType;
  vocabulary?: VocabularyType;
  videoUrl?: string;
  audioUrl?: string;
  text?: string;
};

type GameAction =
  | {
      type:
        | "input_yes"
        | "input_no"
        | "input_next"
        | "input_replay"
        | "controls_play"
        | "controls_pause"
        | "controls_restart"
        | "gameplay_prompt_ended"
        | "gameplay_playback_ended"
        | "gameplay_reward_ended"
        | "gameplay_retry_prompt_ended"
        | "gameplay_solution_playback_ended"
        | "gameplay_audio_hint_playback_ended"
        | "gameplay_vocabulary_cue";
    }
  | {
      type: "controls_change_stage";
      payload: number;
    };

type GameProviderProps = {
  song: SongType;
  stageNumber?: number;
};

const GameContext = createContext<GameContextType | null>(null);

const actions = {
  inputs: {
    yes: "input_yes",
    no: "input_no",
    next: "input_next",
    replay: "input_replay",
  } as const,

  controls: {
    play: "controls_play",
    pause: "controls_pause",
    restart: "controls_restart",
    changeStage: "controls_change_stage",
  } as const,

  gameplay: {
    promptEnded: "gameplay_prompt_ended",
    playbackEnded: "gameplay_playback_ended",
    rewardEnded: "gameplay_reward_ended",
    retryPromptEnded: "gameplay_retry_prompt_ended",
    solutionPlaybackEnded: "gameplay_solution_playback_ended",
    audioHintPlaybackEnded: "gameplay_audio_hint_playback_ended",
    vocabularyCue: "gameplay_vocabulary_cue",
  } as const,
} as const;

export const GameProvider: FC<PropsWithChildren<GameProviderProps>> = ({
  song,
  stageNumber = 1,
  children,
}) => {
  const reducer: Reducer<InternalState, GameAction> = (state, action) => {
    const stage = song.stages.find(
      (stage) => stage.number === state.stageNumber,
    );

    switch (action.type) {
      case actions.controls.play:
        state.playing = true;
        break;
      case actions.controls.pause:
        state.playing = false;
        break;
      case actions.controls.restart:
        state.stageNumber = 1;
        state.playing = true;
        state.retryCount = 0;
        state.vocabularyCueIndex = 0;
        state.phase = "prompt";
        break;
      case actions.controls.changeStage: {
        state.stageNumber = action.payload;
        state.playing = true;
        state.retryCount = 0;

        const nextStage = song.stages.find(
          (stage) => stage.number === state.stageNumber,
        );

        state.phase = nextStage?.promptVideo.url ? "prompt" : "playback";
        state.vocabularyCueIndex = 0;
        break;
      }

      case actions.inputs.yes:
      case actions.inputs.next:
        state.phase = "reward";
        state.playing = true;
        state.retryCount = 0;
        state.vocabularyCueIndex = 0;
        break;
      case actions.inputs.no:
        state.phase = "retry_prompt";
        state.retryCount += 1;
        state.playing = true;

        if (!isEmpty(stage?.hintAudios)) {
          if (state.retryCount >= 4) {
            state.stageNumber += 1;

            const nextStage = song.stages.find(
              (stage) => stage.number === state.stageNumber,
            );

            state.phase = nextStage?.promptVideo.url ? "prompt" : "playback";

            state.retryCount = 0;
          } else if (state.retryCount >= 2) {
            state.phase = "audio_hint_playback";
            state.playing = false;
          }
        }

        break;
      case actions.inputs.replay:
        state.phase = "prompt";
        state.playing = true;
        state.vocabularyCueIndex = 0;
        break;

      case actions.gameplay.vocabularyCue: {
        state.vocabularyCueIndex += 1;
        break;
      }
      case actions.gameplay.promptEnded:
        state.phase = "playback";
        break;
      case actions.gameplay.retryPromptEnded:
        if (state.retryCount >= 2 && isEmpty(stage?.hintAudios)) {
          if (stage?.solutionVideo.url) {
            state.phase = "solution_playback";
          } else {
            state.stageNumber -= 1;

            const previousStage = song.stages.find(
              (stage) => stage.number === state.stageNumber,
            );

            state.phase = previousStage?.promptVideo.url
              ? "prompt"
              : "playback";
            state.retryCount = 0;
          }
        } else {
          state.phase = "playback";
        }
        break;
      case actions.gameplay.playbackEnded:
        state.phase = "input";
        state.playing = false;
        break;
      case actions.gameplay.rewardEnded: {
        state.stageNumber += 1;

        if (state.stageNumber > song.stages.length) {
          state.phase = "stage_complete";
          state.playing = false;
        } else {
          const nextStage = song.stages.find(
            (stage) => stage.number === state.stageNumber,
          );

          state.phase = nextStage?.promptVideo.url ? "prompt" : "playback";
          state.vocabularyCueIndex = 0;
        }
        break;
      }
      case actions.gameplay.solutionPlaybackEnded:
        state.phase = "prompt";
        break;
      case actions.gameplay.audioHintPlaybackEnded:
        state.phase = "input";
        break;
      default:
        break;
    }

    state.lastAction = action.type;

    return state;
  };

  const [state, dispatch] = useImmerReducer<InternalState, GameAction>(
    reducer,
    {
      stageNumber,
      phase: "prompt",
      retryCount: 0,
      playing: true,
      vocabularyCueIndex: 0,
    },
  );

  const stage = useMemo(
    () => song.stages.find((stage) => stage.number === state.stageNumber),
    [song, state.stageNumber],
  );

  const vocabulary = song.vocabularies.find(
    (vocabulary) =>
      vocabulary.id ==
      stage?.vocabularyCues[state.vocabularyCueIndex].vocabularyId,
  );

  const videoUrl = useMemo(() => {
    switch (state.phase) {
      case "prompt":
        return stage?.promptVideo.url;
      case "playback":
        return stage?.video.url;
      case "reward":
        return randomRewardVideoUrl();
      case "retry_prompt":
        return randomRetryPromptUrl();
      case "solution_playback":
        return stage?.solutionVideo.url;
    }
  }, [stage, state.phase]);

  const audioUrl = useMemo(() => {
    switch (state.phase) {
      case "reward":
        return randomRewardAudioUrl();
      case "audio_hint_playback":
        return sample(stage?.hintAudios.map((audio) => audio.url));
      default:
        return undefined;
    }
  }, [stage, state.phase]);

  const handleTimeUpdate: ReactEventHandler<HTMLVideoElement> = (event) => {
    const { phase, vocabularyCueIndex } = state;

    if (phase !== "playback") return;
    if (!stage) return;

    const vocabularyCue = stage.vocabularyCues[vocabularyCueIndex + 1];

    const passedCurrentCue =
      vocabularyCue &&
      event.currentTarget.currentTime >=
        vocabularyCue.timestampMilliseconds / 1000;

    if (passedCurrentCue) dispatch({ type: "gameplay_vocabulary_cue" });
  };

  const handleEnded: ReactEventHandler<HTMLVideoElement> = () => {
    switch (state.phase) {
      case "prompt":
        return dispatch({ type: actions.gameplay.promptEnded });
      case "playback":
        return dispatch({ type: actions.gameplay.playbackEnded });
      case "retry_prompt":
        return dispatch({ type: actions.gameplay.retryPromptEnded });
      case "solution_playback":
        return dispatch({ type: actions.gameplay.solutionPlaybackEnded });
    }
  };

  const handleAudioEnded: ReactEventHandler<HTMLAudioElement> = () => {
    switch (state.phase) {
      case "audio_hint_playback":
        return dispatch({ type: actions.gameplay.audioHintPlaybackEnded });
    }
  };

  useEffect(() => {
    if (state.phase === "reward") {
      setTimeout(() => {
        dispatch({ type: actions.gameplay.rewardEnded });
      }, 4_500);
    }
  }, [state.phase, dispatch]);

  const {
    state: { selectedChildId },
  } = useAppContext();

  useEffect(() => {
    switch (state.lastAction) {
      case actions.inputs.yes:
      case actions.inputs.no:
      case actions.inputs.next:
      case actions.inputs.replay:
      case actions.controls.play:
      case actions.controls.pause:
      case actions.controls.restart:
      case actions.controls.changeStage:
        request.post("/activities", {
          gameplayActivity: {
            action: state.lastAction.replaceAll("_", "-"),
            songId: song.id,
            stageId: stage?.id,
            childId: selectedChildId,
          },
        });
        break;
    }
  }, [state.lastAction, song.id, stage?.id, selectedChildId]);

  return (
    <GameContext.Provider
      value={{
        state: {
          ...state,
          song,
          stage,
          vocabulary,
          videoUrl,
          audioUrl,
        },
        input: {
          yes: () => dispatch({ type: actions.inputs.yes }),
          no: () => dispatch({ type: actions.inputs.no }),
          next: () => dispatch({ type: actions.inputs.next }),
          replay: () => dispatch({ type: actions.inputs.replay }),
        },
        controls: {
          play: () => dispatch({ type: actions.controls.play }),
          pause: () => dispatch({ type: actions.controls.pause }),
          restart: () => dispatch({ type: actions.controls.restart }),
          changeStage: (stageNumber: number) =>
            dispatch({
              type: actions.controls.changeStage,
              payload: stageNumber,
            }),
        },
        eventHandlers: {
          handleEnded,
          handleTimeUpdate,
          handleAudioEnded,
        },
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGameContext = () => {
  const gameContext = useContext(GameContext);

  return gameContext as GameContextType;
};
