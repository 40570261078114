import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGameContext } from "@/contexts/game-context";
import { clsx } from "clsx";

type InputButtonProps = {
  kind: string;
  disabled?: boolean;
};

export const InputButton = ({ kind, disabled = false }: InputButtonProps) => {
  switch (kind) {
    case "yes":
      return YesInputButton({ disabled });
    case "no":
      return NoInputButton({ disabled });
    case "next":
      return NextInputButton({ disabled });
    case "replay":
      return ReplayInputButton({ disabled });
  }

  return null;
};

const YesInputButton = ({ disabled }: Omit<InputButtonProps, "kind">) => {
  const {
    input: { yes },
  } = useGameContext();

  return (
    <button
      className={clsx(
        "rounded-full ring-[3px] ring-white text-gray-800 font-semibold size-16",
        {
          "bg-green-300": disabled,
          "bg-green-400 hover:bg-green-500": !disabled,
        },
      )}
      onClick={() => yes()}
      disabled={disabled}
    >
      <FontAwesomeIcon icon="check" fixedWidth size="xl" />
    </button>
  );
};

const NoInputButton = ({ disabled }: Omit<InputButtonProps, "kind">) => {
  const {
    input: { no },
  } = useGameContext();

  return (
    <button
      className={clsx(
        "rounded-full ring-[3px] ring-white text-gray-800 font-semibold size-16",
        {
          "bg-red-300": disabled,
          "bg-red-400 hover:bg-red-500": !disabled,
        },
      )}
      onClick={() => no()}
      disabled={disabled}
    >
      <FontAwesomeIcon icon="times" fixedWidth size="xl" />
    </button>
  );
};

const NextInputButton = ({ disabled }: Omit<InputButtonProps, "kind">) => {
  const {
    input: { next },
  } = useGameContext();

  return (
    <button
      className={clsx(
        "rounded-full ring-[3px] ring-white text-gray-800 font-semibold size-16",
        {
          "bg-green-300": disabled,
          "bg-green-400 hover:bg-green-500": !disabled,
        },
      )}
      onClick={() => next()}
      disabled={disabled}
    >
      <FontAwesomeIcon icon="arrow-right" fixedWidth size="xl" />
    </button>
  );
};

const ReplayInputButton = ({ disabled }: Omit<InputButtonProps, "kind">) => {
  const {
    input: { replay },
  } = useGameContext();

  return (
    <button
      className={clsx(
        "rounded-full ring-[3px] ring-white text-gray-800 font-semibold size-16",
        {
          "bg-blue-300": disabled,
          "bg-blue-400 hover:bg-blue-500": !disabled,
        },
      )}
      onClick={() => replay()}
      disabled={disabled}
    >
      <FontAwesomeIcon icon="rotate-right" fixedWidth size="xl" />
    </button>
  );
};
