import clsx from "clsx";

import { randomRewardImageUrl } from "@/utils/rewards";

export const Reward = () => {
  return (
    <div className={clsx("flex flex-col gap-4 items-center justify-center")}>
      <img
        src={randomRewardImageUrl()}
        className="h-[300px] sm:h-[500px] aspect-square object-cover rounded-lg ring-4 ring-gray-200"
      />
    </div>
  );
};
