import clsx from "clsx";

type ProgressBarProps = {
  percentage: number;
  text?: string;
  color?: string;
};

const ProgressBar = ({
  percentage,
  text,
  color = "bg-blue-400",
}: ProgressBarProps) => {
  return (
    <div
      className={clsx(
        "h-full text-sm text-center flex items-center justify-center",
        color,
      )}
      style={{ width: `calc(${percentage}%)` }}
    >
      {text}
    </div>
  );
};

export default ProgressBar;
