import { useEffect, useRef, VideoHTMLAttributes } from "react";

import white from "@/assets/white.png";

interface VideoPlayerProps {
  src?: string;
  playing?: boolean;
}

export const VideoPlayer = ({
  src,
  playing = false,
  ...props
}: VideoHTMLAttributes<HTMLVideoElement> & VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (playing) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [playing, src]);

  return <video src={src} ref={videoRef} poster={white} {...props} />;
};
